<template>
  <div class="calendar-content">
    <Calendar
        :task-view-open="taskViewWidgetOpen"
        :tasks="calendarTasks"
        ref="calendar"
        :shared-resource-tasks="sharedResourceTasks"
        @taskUpdated="emitTasksReload"
        @openTaskAddWidget="(value) => openTaskAddWidget(undefined, null, value)"
        @openTaskViewWidget="openTaskViewWidget"
        @monthChanged="handleMonthChange"
    />
    <CalendarSidePanel
        @addParticipants="(value) => openTaskAddWidget(value, 'participants')"
        @openTaskViewWidget="openTaskViewWidget"
        @addTask="(value) => openTaskAddWidget(undefined, null, value)"
        @getTasks="emitTasksReload"
        :error-retrieving-tasks="errorRetrievingTasks"
        :tasks="sidepanelTasks"
    />
    <TaskAddWidget
        @addButtonClicked="addButtonClicked"
        :resource="selectedResource"
        @clearFocusField="focusOnTaskAddField = null"
        :focus-field="focusOnTaskAddField"
        :task-to-edit="taskToEdit"
        @getTasks="emitTasksReload"
        @closeWidget="closeTaskAddWidget"
        :new-task-time="taskAddWidgetTaskTime"
        :newCreatedContact="newCreatedContact"
        :contactModalOpen="contactModalOpen"
        v-if="taskAddWidgetOpen"
    />
    <TaskViewWidget
        @openProjectSidepanel="openProjectSidepanel"
        @openContactSidepanel="openContactSidepanel"
        @addParticipants="(value) => openTaskAddWidget(value, 'participants')"
        @openDeleteModal="openDeleteModal"
        @taskUpdated="viewTaskUpdated"
        @openTask="openParentTask"
        @editTask="openTaskAddWidget"
        :task="taskViewWidgetValue"
        @closeWidget="closeTaskViewWidget"
        v-if="taskViewWidgetOpen && !isDeleteModalOpen"
    />
    <DeleteModal
        @canceled="isDeleteModalOpen = false"
        @approved="deleteTask"
        v-if="isDeleteModalOpen"
    />
  </div>
</template>

<script>
import Calendar from "./Calendar";
import CalendarSidePanel from "./CalendarSidePanel";
import TaskAddWidget from "@/components/CalendarView/TaskAddWidget";
import TaskViewWidget from "@/components/CalendarView/TaskViewWidget";
import axios from "axios";
import DeleteModal from "@/components/common/DeleteModal";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "Content",
  props: [
    "sidepanelTasks",
    "calendarTasks",
    "errorRetrievingTasks",
    "selectedResource",
    "sharedResourceTasks",
    "selectedContactId",
    "selectedProjectId",
  ],
  components: {
    DeleteModal,
    TaskViewWidget,
    TaskAddWidget,
    Calendar,
    CalendarSidePanel,
  },
  data() {
    return {
      taskAddWidgetOpen: false,
      taskAddWidgetTaskTime: null,
      taskToEdit: undefined,
      taskViewWidgetOpen: false,
      taskViewWidgetValue: null,
      isDeleteModalOpen: false,
      focusOnTaskAddField: null,
      resourceView: false,
      allowCloseTaskViewWidget: true,
      contactModalOpen: false,
      contactModalRecentlyOpened: false,
      newCreatedContact: null,
    };
  },
  watch: {
    "$route.query": async function () {
      if (this.$route.query.notificationId) {
        await this.$emit("getTasks");
      }
      const openTaskFromNotification = setInterval(() => {
        if (this.calendarTasks && this.$route.query.notificationId) {
          const eventId = this.$route.query.notificationId;
          const openedTask = this.calendarTasks.find(
              (task) => task._id === eventId
          );
          this.openTaskViewWidget(openedTask);
          clearInterval(openTaskFromNotification);
        }
      }, 200);
    },
    selectedContactId(value) {
      if (value === null) {
        this.allowCloseTaskViewWidget = false;
      }
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async openParentTask(taskId) {
      const task = this.calendarTasks.find((task) => task._id === taskId);
      if (task) {
        await this.openTaskViewWidget(task)
      } else {
        const response = await axios.get(`/api/task/${taskId}`);
        if (response && response.data) {
          await this.openTaskViewWidget(response.data)
        }

      }
    },
    passCustomerIdAndObject(customer, customerId) {
      this.newCreatedContact = {
        id: customerId,
        name: customer.customerName,
      };
    },
    addButtonClicked() {
      this.contactModalOpen = true;
      this.contactModalRecentlyOpened = true;
    },
    closeAddModal() {
      this.contactModalOpen = false;
    },
    openProjectSidepanel(projectId) {
      this.$emit("openProjectSidepanel", projectId);
    },
    openContactSidepanel(contactId) {
      this.$emit("openContactSidepanel", contactId);
    },
    emitTasksReload() {
      this.$emit("getTasks");
    },
    openDeleteModal() {
      this.isDeleteModalOpen = true;
    },
    async deleteTask() {
      let msToken = null;
      if (this.user.microsoft) {
        const access = await this.getMsalAccessToken();
        if (access) {
          msToken = access.accessToken;
        }
      }
      await axios
          .post(`/api/task/delete/${this.taskViewWidgetValue._id}`, {
            accessToken: msToken,
          })
          .then((response) => {
            if (response && response.status === 200) {
              this.toastSuccess(this.$t("Deleted"));
              this.closeTaskViewWidget();
              this.isDeleteModalOpen = false;
              this.$emit("getTasks");
            } else {
              this.toastError(this.$t("ErrorDeleting"));
            }
          })
          .catch(() => {
            this.toastError(this.$t("ErrorDeleting"));
          });
    },
    openTaskAddWidget(task = undefined, focusField = null, taskTime = null) {
      if (this.taskViewWidgetOpen) {
        this.allowCloseTaskViewWidget = true;
      }
      if (task) {
        this.taskToEdit = task;
        if (focusField) {
          this.focusOnTaskAddField = focusField;
        }
        this.closeTaskViewWidget();
      }

      if (!taskTime && !task) {
        const currentDate = moment().add(30, 'm')
        let startDate = moment(currentDate);
        startDate = moment(this.roundTime(startDate))
        let endDate = moment(currentDate).add(30, 'm');
        endDate = moment(this.roundTime(endDate, false))
        const date = {
          startTime: startDate.format("HH:mm"),
          endTime: endDate.format("HH:mm"),
          startDate: startDate,
          endDate: endDate,
        }
        this.taskAddWidgetTaskTime = date;
      } else {
        this.taskAddWidgetTaskTime = taskTime;
      }
      if (this.selectedSharedResourceTasks) {
        this.resourceView = true;
      }
      this.taskAddWidgetOpen = true;
    },
    closeTaskAddWidget(updatedTask) {
      if (!this.contactModalOpen && !this.contactModalRecentlyOpened) {
        this.taskAddWidgetTaskTime = null;
        this.taskAddWidgetOpen = false;
        if (this.taskToEdit && updatedTask) {
          this.openTaskViewWidget(updatedTask);
        }
        this.taskToEdit = undefined;
      }
      this.contactModalRecentlyOpened = false;
    },
    async openTaskViewWidget(openedTask) {
      const eventTime = openedTask?.dates?.startDate;
      if (eventTime && openedTask?.dates.startTime) {
        this.$refs.calendar.scrollToEventTime(eventTime);
      }
      window.addEventListener("click", this.taskClickListener);
      this.taskViewWidgetOpen = true;
      this.taskViewWidgetValue = openedTask;
    },
    taskClickListener(event) {
      const path = event.path || (event.composedPath && event.composedPath());
      let anyPathTrue = false;
      path.forEach((innerPath) => {
        if (
            innerPath.classList &&
            innerPath.classList.contains("task-container")
        ) {
          anyPathTrue = true;
        }
      });
      if (anyPathTrue) {
        this.allowCloseTaskViewWidget = false;
      } else {
        this.allowCloseTaskViewWidget = true;
      }
    },
    closeTaskViewWidget() {
      window.removeEventListener("click", this.taskClickListener);
      if (this.selectedContactId || !this.allowCloseTaskViewWidget) {
        this.allowCloseTaskViewWidget = true;
        return;
      }
      this.taskViewWidgetOpen = false;
      this.taskViewWidgetValue = null;
    },
    viewTaskUpdated(task) {
      this.$emit("viewTaskUpdated", task);
    },
    calendarTaskUpdated() {
      this.$emit("getTasks", "sidepanel");
    },
    roundTime(time, down = true) {
      const roundTo = 30; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
          ? time - (time % roundDownTime)
          : time + (roundDownTime - (time % roundDownTime));
    },
    handleMonthChange(month) {
      this.$emit('monthChanged', month);
    },
  },
};
</script>

<style scoped>
.calendar-content {
  margin-left: 64px;
  width: 100%;
  height: calc(100vh - 220px);
  min-height: 800px;
  max-height: 1200px;
  padding-top: 24px;
  padding-left: 48px;
  padding-bottom: 72px;
  display: flex;
  gap: 24px;
  flex-wrap: nowrap;
  overflow-x: auto;
}
</style>
