<template>
  <div class="calendar-wrap">
    <div class="calendar-main">
      <v-sheet class="calendar-calendar-header" :style="calendarContentStyle">
        <div style="margin-left: 16px; display: flex; flex-wrap: nowrap">
          <div style="display: flex">
            <div
                style="cursor: pointer; display: flex; align-items: center"
                @click="calendarChoice('previous')"
            >
              <img src="../../assets/images/chevron-left.svg"/>
            </div>
          </div>
          <h5
              v-if="$refs.calendar"
              style="margin: 14px 10px 10px 10px; cursor: pointer"
          >
            {{ $refs.calendar.title }}
          </h5>
          <div style="display: flex">
            <div
                style="cursor: pointer; display: flex; align-items: center"
                @click="calendarChoice('next')"
            >
              <img src="../../assets/images/chevron-right.svg"/>
            </div>
          </div>
        </div>
        <div style="display: flex; align-items: center; margin: 0 16px">
          <button @click="setCalendarToToday" class="outlined-small-button">
            {{ $t("Today") }}
          </button>
        </div>
        <v-spacer></v-spacer>
        <div class="calendar-togglers">
          <div v-if="showWeekends !== undefined" class="weekend-toggler">
            <BaseToggle v-model="showWeekends" :label="$t('showWeekends')"/>
          </div>
          <BaseRadioGroup v-model="type" :items="calendarViewItems" display-key="name" display-value="value" />
          <div
            :class="isCalendars ? 'calendars-show' : 'calendars-hide'"
            @click="toggleIsCalendars"
            class="calendar-details-toggler"
            v-if="user.access?.features?.modules?.calendar?.features?.sharing?.enabled"
          >
            <img
              src="@/assets/images/users_16_16.svg"
              alt=""
            />
            <p>
              {{ $t("UsersAndResources") }}
            </p>
          </div>
        </div>
      </v-sheet>
      <v-sheet class="calendar-calendar-content">
        <v-calendar
            @click:time="openTaskAddWidget"
            @click:event="openTaskViewWidget"
            @click:day="openTaskAddWidgetMonth"
            @click:date="viewDay"
            @click:more="openMoreTasksWidget"
            @mousedown:event="startDragEvent"
            @mouseup:time="endDragEvent"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseleave.native="cancelDragEvent"
            @input="updateCurrentMonth"
            :interval-format="intervalFormat"
            :locale="language"
            :weekdays="weekday"
            :events="events"
            :type="type"
            ref="calendar"
            class="calendar"
            v-model="focus"
        >    
          <template v-slot:event="{ event }">
            <div :style="{background: event.sideColor}" class="v-event-side"></div>
            <div class="v-event-draggable">
              <div class="content-small event-name">
                {{ event.name }}
                <span v-if="event.task && event.task.dates" class="content-small event-date">{{
                    event.task.dates.startTime
                  }} - {{ event.task.dates.endTime }}</span></div>
            </div>
          </template>
          <template v-slot:day-body="{ date, week }">
            <div v-for="day in week" :key="day.date">
              <div
                  v-if="date === day.date && day.present === true"
                  class="v-current-time"
                  :style="{ top: nowY }"
              ></div>
            </div>
          </template>
        </v-calendar>
      </v-sheet>
    </div>
    <v-sheet class="calendar-view-panel" v-if="isCalendars">
      <div class="calendar-sidepanel-header">
        <h5 class="calendar-sidepanel-header-title"> 
        {{ $t('UsersAndResources') }}
        </h5>
      </div>
      <div class="search-wrapper">
        <TaskLinkedToSearch :custom-placeholder="$t('AddUser')" :default-search="true" :items="userColleagues" v-model="selectedColleagueId" />
      </div>
      <div class="toggle-wrapper">
        <div v-if="showMyCalendar !== undefined">
          <BaseToggle v-model="showMyCalendar" :label="$t('MyCalendar')" toggle-colour="black"/>
        </div>
        <div v-if="showResources !== undefined">
          <BaseToggle v-model="showResources" :label="$t('showResources')" toggle-colour="turquoise"/>
        </div>
      </div>
      <div class="user-groups">
        <div v-if="selectedColleaguesCalendars.length > 0" :key="'otherSection'">
          <div class="group-calendars">
            <div class="group-calendar-header" @click="openCalendar('otherSection')">
              <div class="group-calendar-title content-small semi-bold">{{ $t('Other') }}</div>
              <img :src="getIconSrc('otherSection')" alt="" />
            </div>
            <div class="group-calendar-wrapper" v-if="openAccordions.includes('otherSection')">
              <div v-for="(colleague, index) in selectedColleaguesCalendars" :key="colleague.id">
                <div class="group-calendar-item-wrapper" v-if="refreshKey">
                  <div class="group-calendar-item-left">
                    <BaseToggle 
                      :value="checkedUsers[colleague.id]" 
                      @change="handleToggleChange(colleague.id, $event)" 
                      :label="colleague.name" 
                      toggle-colour="black"
                    />
                  </div>
                  <img
                    @click="removeColleague(index)"
                    :src="require('@/assets/images/close_24_24.svg')"
                    style="cursor: pointer"
                    width="20"
                    height="20"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-for="(item, index) in userGroups" :key="item.id">
          <div class="group-calendars">
            <div class="group-calendar-header"  @click="openCalendar(index)">
              <div class="group-calendar-title content-small semi-bold">{{ item.name }}</div>
              <img :src="getIconSrc(index)" alt="" />
            </div>
            <div class="group-calendar-wrapper" v-if="openAccordions.includes(index)">
              <div class="group-calendar" v-for="user in item.groupUsers" :key="user.id">
                <div class="group-calendar-item-wrapper" v-if="refreshKey">
                  <div class="group-calendar-item-left">
                    <BaseToggle 
                      :value="checkedUsers[user.id]" 
                      @change="handleToggleChange(user.id, $event)" 
                      :label="user.name" 
                      toggle-colour="black"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-sheet>
    <TasksViewMoreWidget
        v-if="tasksViewMore"
        :widget-information="tasksViewMore"
        @openTaskViewWidget="openTaskViewWidgetFromViewMore"
        @closeWidget="closeMoreTasksWidget"
    />
  </div>
</template>

<script>
import taskTypes from "@/components/CalendarView/taskTypes";
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import TasksViewMoreWidget from "@/components/CalendarView/TasksViewMoreWidget";
import BaseToggle from "@/components/common/BaseToggle";
import BaseRadioGroup from "@/components/common/BaseRadioGroup";
import TaskLinkedToSearch from "@/components/CalendarView/TaskLinkedToSearch";

export default {
  name: "Calendar",
  components: {BaseRadioGroup, BaseToggle, TasksViewMoreWidget, TaskLinkedToSearch},
  props: ["tasks", "sharedResourceTasks", "taskViewOpen"],
  data() {
    return {
      weekday: undefined,
      showWeekends: undefined,
      showResources: undefined,
      showMyCalendar: undefined,
      type: "week",
      ready: false,
      focus: "",
      events: [],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      tasksViewMore: null,
      mapTasks: true,
      calendarViewItems: [
        {
          name: this.$t('day'),
          value: 'day'
        },
        {
          name: this.$t('Week'),
          value: 'week'
        },
        {
          name: this.$t('Month'),
          value: 'month'
        }
      ],
      calendar: {
        selectedUser: ""
      },
      userGroups: [],
      userColleagues: [],
      selectedColleagueId: null,
      currentlySelectedUserId: null,
      refreshKey: true,
      currentMonth: null,
    };
  },
  watch: {
    tasks() {
      this.loadEventsToCalender();
    },
    sharedResourceTasks() {
      this.loadEventsToCalender();
    },
    showWeekends(value) {
      if (value) {
        this.weekday = [1, 2, 3, 4, 5, 6, 0]
        this.setCalendarShowWeekends(true)
      } else {
        this.weekday = [1, 2, 3, 4, 5]
        this.setCalendarShowWeekends(false);
      }
    },
    showResources(value) {
      if (value) {
        this.setCalendarShowResources(true)
      } else {
        this.setCalendarShowResources(false);
      }
    },
    showMyCalendar(value) {
      if (value) {
        this.setCalendarShowMyCalendar(true);
        this.addUserTasks(this.tasks);
      } else {
        this.setCalendarShowMyCalendar(false);
        this.removeUserTasks(this.tasks);
      }
    },
    selectedColleagueId(newValue) {
      if (newValue) {
        const colleague = this.userColleagues.find(user => user.id === newValue);
        if (colleague && !this.selectedColleaguesCalendars.some(user => user.id === colleague.id)) {
          this.selectedColleaguesCalendars.push(colleague);
        }
        this.selectedColleagueId = null;
      }
    },
    type(newValue) {
      switch (newValue) {
        case 'day':
          this.scrollToCurrentTime();
            break;
        case 'week':
          this.scrollToCurrentTime();
      }
      this.setCalendarView(newValue);
    },
    selectedColleaguesCalendars: {
      handler(newVal, oldVal) {
        if (newVal.length > 0 && oldVal.length === 0 && !this.openAccordions.includes('otherSection')) {
          this.toggleAccordion('otherSection');
        }
      },
      deep: true
    },
    checkedUsers: {
      handler(newVal) {
        this.setCheckedUsers(newVal);
      },
      deep: true
    }
  },
  mounted() {
    this.ready = true;
    this.$refs.calendar.move(0);
    this.scrollToCurrentTime();
    this.setupColleagues();
    this.setupGroups();

    if (this.calendarShowWeekends) {
      this.showWeekends = true;
    } else {
      this.showWeekends = false;
    }
    if (this.calendarShowResources) {
      this.showResources = true;
    } else {
      this.showResources = false;
    }
    if (this.calendarShowMyCalendar) {
      this.showMyCalendar = true;
    } else {
      this.showMyCalendar = false;
    }

    if (this.calendarView) {
      this.type = this.calendarView;
    }
  },
  computed: {
    ...mapGetters(["language", "user", "users", "calendarShowWeekends", "calendarShowResources", "calendarShowMyCalendar", "calendarView", "selectedColleaguesCalendars", "checkedUsers",  "getUserTasks", "isCalendars", "openAccordions"]),
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    calendarContentStyle() {
      return this.isCalendars ? { borderRadius: '8px 0 0 0' } : { borderRadius: '8px 8px 0 0' };
    }
  },
  methods: {
    ...mapActions(["setCalendarShowWeekends", "setCalendarShowResources", "setCalendarShowMyCalendar", "setCalendarView", "setSelectedColleagues", "setCheckedUsers", "setUserTasks", "removeUserTask", "toggleIsCalendars", "toggleAccordion", "setOpenAccordions"]),
    viewDay({date}) {
      this.focus = date;
      this.type = "day";
    },
    loadUserTasksToCalendar() {
      const allUserTasks = Object.values(this.getUserTasks).flat();
      const mappedTasks = allUserTasks.map(this.mapTaskToEvent).filter(event => event !== null && event !== undefined);
      this.events = [...this.events, ...mappedTasks];
    },
    async handleToggleChange(userId, value) {
      this.$set(this.checkedUsers, userId, value);

      const updatedCheckedUsers = { ...this.checkedUsers, [userId]: value };
      this.setCheckedUsers(updatedCheckedUsers);
      const userGroup = this.userGroups.find(group => group.groupUsers.some(user => user.id === userId));
      const user = userGroup ? userGroup.groupUsers.find(user => user.id === userId) : null;

      if (user && (!user.tasks || user.tasks.length === 0)) {
        user.tasks = await this.fetchUserTasks(userId);
        this.setUserTasks({ userId, tasks: user.tasks });
      }
      if (value) {
          this.setUserTasks({ userId, tasks: user.tasks });
      } else {
        this.removeUserTask(userId);
        this.removeUserTasks(user.tasks);
      }
      
      this.updateCalendarEvents(userId);

      this.refreshKey = false;
      this.$nextTick(() => {
        this.refreshKey = true;
      });
    },
    getIconSrc(index) {
      if (this.openAccordions.includes(index)) {
        return require('@/assets/images/chevron.svg');
      }
      return require('@/assets/images/chevron_24_24.svg');
    },
    scrollToCurrentTime() {
      const minutesToTakeBack = 60;
      let currentTime = new Date();
      const minutesTakenBackTime = new Date(
          currentTime.getTime() - minutesToTakeBack * 60000
      );
      currentTime = moment(minutesTakenBackTime).format("HH:mm");
      this.$nextTick(() => {
        this.$refs.calendar.scrollToTime(currentTime);
      });
    },
    scrollToEventTime(eventTime) {
      if (!eventTime) return;
      const time = moment(eventTime).subtract(1, "h").format("HH:mm");

      if (moment(eventTime).isSame(new Date(), "week")) {
        this.$refs.calendar.scrollToTime(time);
      }
    },
    intervalFormat(interval) {
      return interval.time;
    },
    loadEventsToCalender() {
      this.events = [];
      this.mapResourcesToCalendar();
      this.mapTasksToCalendar();
      this.loadUserTasksToCalendar();
    },
    mapTasksToCalendar() {
      if (this.calendarShowMyCalendar) {
        this.tasks.forEach(task => {
          const calendarTask = this.mapTaskToEvent(task);
          if (calendarTask) {
            this.events.push(calendarTask);
          }
        });
      }
    },
    async fetchUserTasks(id) {
      try {
        const response = await axios.get(`api/tasks/creator/${id}`);
        return response.data;
      } catch (error) {
        console.error(`Error fetching tasks for user with ID: ${id}`, error);
        return [];
      }
    },
    mapTaskToEvent(task, defaultToBlack = false) {
      const correctTaskType = taskTypes.find(taskType => taskType.value === task.type);
      if (!correctTaskType) {
        return null;
      }

      if (task.creator && task.creator.userId === this.user._id) {
        defaultToBlack = false;
      }

      const defaultColor = defaultToBlack ? 'gray' : correctTaskType.headerColor;
      const sideColor = defaultToBlack ? 'black' : correctTaskType.color;

      const calendarTask = {
        task: task,
        name: task.category === "open_house" ? task.linkedProject.name : task.content,
        color: defaultColor,
        sideColor: sideColor,
      };

      if (task.dates.startTime && task.dates.endTime && task.dates.startDate && task.dates.endDate) {
        calendarTask.start = new Date(task.dates.startDate);
        calendarTask.end = new Date(task.dates.endDate);
        calendarTask.timed = true;
      } else if (task.dates.endDate && task.dates.startDate && !task.dates.startTime && !task.dates.endTime) {
        calendarTask.start = new Date(task.dates.startDate);
        calendarTask.end = new Date(task.dates.endDate);
        calendarTask.timed = false;
      } else {
        return null;
      }

      return calendarTask;
    },
    mapResourcesToCalendar() {
      this.sharedResourceTasks.forEach(task => {
        const calendarTask = this.mapTaskToEvent(task);
        if (calendarTask) {
          this.events.push(calendarTask);
        }
      });
    },
    openAccordion(accordionKey) {
      if (this.openAccordions.includes(accordionKey)) {
          this.CLOSE_ACCORDION(accordionKey);
      } else {
          this.OPEN_ACCORDION(accordionKey);
      }
    },
    setCalendarToToday() {
      this.focus = "";
    },
    async setupGroups() {
      for (const group of this.user.groups) {
        const groupUsersData = await this.getGroupUsers(group._id);
        const groupUsers = Object.keys(groupUsersData).map(userId => {
          return {
            id: userId,
            name: groupUsersData[userId].name,
          };
        }).filter(user => user.id !== this.user._id);

        const userGroup = {
          id: group._id,
          name: group.name,
          groupUsers: groupUsers
        };
        this.userGroups.push(userGroup);
      }
      return this.userGroups;
    },
    async setupColleagues() {
      
      this.users.forEach((user) => {
        if (user._id === this.user._id) {
          return;
        }
        if (user.userStatus === "Active") {
          const formattedUser = {
            id: user._id,
            name: user.name,
          };
          this.userColleagues.push(formattedUser);
        }
      });

      this.userColleagues.sort((a, b) => a.name.localeCompare(b.name));
    },
    toggleUserCheck(user) {
      this.$set(this.checkedUsers, user.id, !this.checkedUsers[user.id]);
      this.updateCalendarEvents(user.id);
    },
    removeColleague(index) {
      this.selectedColleaguesCalendars.splice(index, 1);
    },
    updateCalendarEvents(userId) {
      let userTasks = null;

      for (const group of this.userGroups) {
        const foundUser = group.groupUsers.find(u => u.id === userId);
        if (foundUser) {
          userTasks = foundUser.tasks;
          break;
        }
      }
      if (!userTasks) {
        console.error(`No tasks found for user ID: ${userId}`);
        return;
      }
      if (this.checkedUsers[userId]) {
        this.addUserTasks(userTasks);
      } else {
        this.removeUserTasks(userTasks);
      }
    },
    addUserTasks(tasks) {
      const newEvents = tasks.map(task => this.mapTaskToEvent(task, true)).filter(event => event);
      this.events = [...this.events, ...newEvents];
    },
    removeUserTasks(tasks) {
      const taskIds = tasks.map(task => task._id);
      this.events = this.events.filter(event => !taskIds.includes(event.task._id));
    },
    calendarChoice(choiceName) {
      if (choiceName === "previous") {
        this.$refs.calendar.prev();
      } else {
        this.$refs.calendar.next();
      }
      this.scrollToCurrentTime();
    },
    startDragEvent({event, timed}) {
      if (event && timed && event.task.category !== "resource") {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      }
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      }
    },
    async updateTask(updatedTask) {
      if (this.user.microsoft) {
        const access = await this.getMsalAccessToken();
        if (access) {
          updatedTask.accessToken = access.accessToken;
        }
      }
      await axios.patch(`/api/task/${updatedTask._id}`, updatedTask);
      this.$emit("taskUpdated");
    },
    endDragEvent() {
      if (this.dragEvent) {
        const newStartDate = moment(this.dragEvent.start);
        newStartDate.set({
          h: new Date(this.dragEvent.start).getHours(),
          m: new Date(this.dragEvent.start).getMinutes(),
        });
        const newEndDate = moment(this.dragEvent.end);
        newEndDate.set({
          h: new Date(this.dragEvent.end).getHours(),
          m: new Date(this.dragEvent.end).getMinutes(),
        });
        const startTime = newStartDate.format("HH:mm");
        const endTime = newEndDate.format("HH:mm");

        const oldDates = {
          startTime: this.dragEvent.task.dates.startTime,
          endTime: this.dragEvent.task.dates.endTime,
          startDate: this.dragEvent.task.dates.startDate,
          endDate: this.dragEvent.task.dates.endDate,
        };
        const newDates = {
          startTime,
          endTime,
          startDate: newStartDate,
          endDate: newEndDate,
        };
        if (JSON.stringify(oldDates) === JSON.stringify(newDates)) {
          this.dragEvent = null;
          return;
        }
        this.dragEvent.task.dates.startTime = startTime;
        this.dragEvent.task.dates.endTime = endTime;
        this.dragEvent.task.dates.startDate = newStartDate.format();
        this.dragEvent.task.dates.endDate = newEndDate.format();
        this.updateTask(this.dragEvent.task);
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDragEvent() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    toTime(tms) {
      return new Date(
          tms.year,
          tms.month - 1,
          tms.day,
          tms.hour,
          tms.minute
      ).getTime();
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
          ? time - (time % roundDownTime)
          : time + (roundDownTime - (time % roundDownTime));
    },
    openMoreTasksWidget(value) {
      value.nativeEvent.stopPropagation();
      const position = value.nativeEvent.target.getBoundingClientRect();
      const clickedDateStart = moment(value.date).startOf("day");
      const clickedDateEnd = moment(value.date).endOf("day");
      // task startDate is before end of givenDay  and after start of givenDay OR task endDate is after start OR BEFORE End
      const taskViewTasks = this.tasks.filter(
          (task) =>
              moment(task.dates.startDate).isAfter(clickedDateStart) &&
              moment(task.dates.startDate).isBefore(clickedDateEnd)
      );
      const taskAllDayTasks = this.tasks.filter(
          (task) => moment(task.dates.startDate).diff(clickedDateStart) === 0
      );
      this.tasksViewMore = {
        position: position,
        tasks: taskViewTasks,
        allDayTasks: taskAllDayTasks,
      };
    },
    closeMoreTasksWidget() {
      this.tasksViewMore = null;
    },
    openTaskAddWidgetMonth(value) {
      if (this.taskViewOpen || this.tasksViewMore) return;
      value.nativeEvent.stopPropagation();
      let startDate = moment(value.date);
      startDate.set({h: value.hour, m: value.minute});
      startDate = moment(this.roundTime(startDate));
      let endDate = moment(value.date);
      endDate = moment(this.roundTime(endDate, false));
      const date = {
        startTime: "14:00",
        endTime: "15:00",
        startDate: startDate,
        endDate: endDate,
      };
      this.$emit("openTaskAddWidget", date);
    },
    openTaskAddWidget(value) {
      if (this.taskViewOpen) return;
      value.nativeEvent.stopPropagation();
      let startDate = moment(value.date);
      startDate.set({h: value.hour, m: value.minute});
      startDate = moment(this.roundTime(startDate));
      let endDate = moment(value.date);
      endDate.set({h: value.hour, m: value.minute}).add(45, 'minutes');
      endDate = moment(this.roundTime(endDate, false));
      const date = {
        startTime: startDate.format("HH:mm"),
        endTime: endDate.format("HH:mm"),
        startDate: startDate,
        endDate: endDate,
      };
      this.$emit("openTaskAddWidget", date);
    },
    openTaskViewWidget(value) {
      value.nativeEvent.stopPropagation();
      this.$emit("openTaskViewWidget", value.event.task);
    },
    openTaskViewWidgetFromViewMore(value) {
      this.$emit("openTaskViewWidget", value);
    },
    openCalendar(accordionId) {
      this.toggleAccordion(accordionId);
    },
    updateCurrentMonth(value) {
      this.currentMonth = moment(value);
      this.$emit('monthChanged', this.currentMonth.format('YYYY-MM'));
    },
  },
};
</script>

<style scoped>
.user-groups {
  height: auto;
  overflow-y: auto;
  display: flex; 
  flex-direction: column; 
  gap: 24px; 
}
.toggle-wrapper {
  display: flex; 
  flex-direction: column; 
  gap: 8px; 
  margin-right: 8px; 
  margin: 0 20px;
}

.event-date {
  font-weight: 500; 
  line-height: 18px; 
  display: inline; 
  white-space: normal; 
  word-break: break-word;
}

.event-name {
  font-weight: 600;
  display: inline-block; 
  height: 100%; 
  line-height: 18px; 
  white-space: normal;
}

.weekend-toggler {
  display: flex; 
  align-items: center; 
  margin-right: 16px
}
.calendar-details-toggler {
  width: 162px; 
  height: 28px; 
  border-radius: 6px; 
  padding: 0px 6px; 
  font-size: 12px; 
  display: flex; 
  align-items: center; 
  justify-content: space-between; 
  cursor: pointer;
}

.search-wrapper {
  display: flex; 
  align-items: center;
  margin-right: 8px; 
  margin: 0 20px;
}

.calendar-togglers {
  display: flex; 
  gap: 8px; 
  margin-right: 16px; 
  align-items: center; 
  white-space: nowrap
}

.group-calendar-wrapper {
  overflow: auto; 
  display: flex; 
  flex-direction: 
  column; gap: 8px;
}

.group-calendars {
  margin: 0 20px; 
  font-size: 14px; 
  display: flex; 
  flex-direction: column; 
  gap: 8px;
}

.group-calendar-header  {
  display: flex; 
  align-items: center; 
  justify-content: space-between; 
  cursor: pointer;
}

.calendars-show {
  font-weight: 600;
  border: 2px solid #000000;
  flex-basis: 100%;
  text-align: center;
  background: #fff;
}

.calendars-hide {
  background: #fff;
  flex-basis: 100%;
  text-align: center;
  border: 1px solid #e6e8ec;
}

.group-calendar-item-wrapper {
  display: flex;
  justify-content: space-between;
}
.group-calendar-item-left {
  display: flex;
}
.group-calendar {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.task-linked-to-search-input {
  height: 40px;
  border-radius: 8px;
  margin: 0px 20px;
  padding: 6px 8px;
  border: 1px solid #e6e8ec;
  display: flex;
  gap: 8px;
  align-items: center;
}

.task-linked-to-search-input input {
  outline: none;
  border: none;
  height: 28px;
  width: 100%;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}

.v-current-time::before {
  content: "";
  position: absolute;
  background-color: #ea4335;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}

.calendar-calendar-header {
  height: 64px;
  display: flex;
  border-radius: 8px 0 0 0;
  border-bottom: 2px solid #e6e8ec;
}

.calendar {
  border: none;
  border-radius: 8px;
}

.calendar-wrap {
  min-width: 820px;
  max-width: 1200px;
  height: 100%;
  display: flex;
  flex-shrink: 0;
}

.calendar-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.calendar-view-panel {
  width: 280px;
  border-left: 2px solid #e6e8ec;
  border-radius: 0 8px 8px 0;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.calendar-sidepanel-header {
  display: flex;
  flex-wrap: nowrap;
  gap: 32px;
  padding-right: 20px;
  padding-left: 20px;
}

.active-view-button {
  background: #f4f5f7;
}

.v-event-draggable {
  padding-left: 10px;
  padding-right: 4px;
  color: black;
}

.v-event-side {
  min-width: 6px;
  height: 100%;
  position: absolute;
  border-radius: 4px 0 0 4px;
}

/* Vuetify style changes */

.calendar-calendar-content {
  height: calc(100% - 64px);
}

::v-deep .theme--light.v-btn.v-btn--has-bg {
  background: black;
  color: white;
}

::v-deep .v-btn.transparent {
  background: transparent !important;
  color: black !important;
}

::v-deep .v-btn {
  border-radius: 30px;
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
}

::v-deep .theme--light.v-calendar-daily .v-calendar-daily_head-day:last-child {
  border-right: 0;
}

::v-deep .theme--light.v-calendar-daily .v-calendar-daily__day:last-child {
  border-right: 0;
}

::v-deep .theme--light.v-calendar-daily .v-calendar-daily__day {
  border-bottom: 0;
}

::v-deep .v-calendar-daily_head-day-label {
  margin-top: 4px;
}

::v-deep .v-event-timed {
  overflow: hidden !important;
}
</style>
